<template>
  <div id="view-admin-user-managment">
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="false"
      class="loading"
    ></b-loading>

    <article class="">
      <div class="media left">
        <div class="media-left is-hidden-touch">
          <figure class="image is-48x48">
            <img src="/icon/confg_48x48.png" class="icon" alt="Support Icon" />
          </figure>
        </div>
        <div class="media-content">
          <div class="content">
            <div class="title is-5">User Management</div>
            <div class="level">
              <div class="level-left">
                <b-select
                  v-model="perPage"
                  :disabled="!isPaginated"
                  class="level-item"
                >
                  <option value="5">5 per page</option>
                  <option value="10">10 per page</option>
                  <option value="15">15 per page</option>
                  <option value="20">20 per page</option>
                </b-select>
              </div>
              <div class="level-right">
                <a @click="onAdd()" class="button is-small is-success is-light"
                  >New</a
                >
              </div>
            </div>
            <section>
              <b-loading
                :is-full-page="false"
                v-model="isLoading"
                :can-cancel="false"
              ></b-loading>

              <b-table
                :data="list"
                :paginated="isPaginated"
                :per-page="perPage"
                :current-page.sync="currentPage"
                :pagination-simple="isPaginationSimple"
                :pagination-position="paginationPosition"
                striped
                default-sort="name"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page"
                icon-pack="fas"
              >
                <b-table-column
                  field="Id"
                  label="ID"
                  width="30"
                  numeric
                  v-slot="props"
                >
                  {{ props.row.loginId }}
                </b-table-column>

                <b-table-column width="auto" label="Name" v-slot="props">
                  {{ nameForRow(props.row) }}
                </b-table-column>

                <b-table-column label="User" width="160" v-slot="props">
                  {{ props.row.userName }}
                </b-table-column>

                <b-table-column label="Tools" v-slot="props" sticky width="140">
                  <span>
                    <a
                      @click="onEdit(props.row)"
                      class="button is-small is-primary is-light mr-1"
                      >Edit</a
                    >
                    <a
                      @click="onDelete(props.row)"
                      class="button is-small is-danger is-light"
                      >Delete</a
                    >
                  </span>
                </b-table-column>
              </b-table>
            </section>
          </div>
          <div class="footnotes is-hidden">
            <ol>
              <li id="fn:1"></li>
            </ol>
          </div>
        </div>
      </div>
    </article>
    <b-modal
      v-model="isAddDialogActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      :can-cancel="false"
      width="80%"
      scroll="keep"
      aria-role="dialog"
      aria-modal
      class="nomodal"
    >
      <template #default>
        <add-dialog
          :session="session"
          @onComplete="onAddComplete"
          @onCancel="onAddCancel"
        ></add-dialog>
      </template>
    </b-modal>
    <b-modal
      v-model="isEditDialogActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      :can-cancel="false"
      width="80%"
      scroll="keep"
      aria-role="dialog"
      aria-modal
      class="nomodal"
    >
      <template #default>
        <edit-dialog
          :session="session"
          :edit="editItem"
          @onComplete="onEditComplete"
          @onCancel="onEditCancel"
        ></edit-dialog>
      </template>
    </b-modal>
  </div>
</template>

<script>
// TODO: implement smart pagination of article list as the simple will hit a wall when the counts get too high
// TODO: Rework to APIResult model

import API from "@/Druware.Web.Shared/api/api";
import AddDialog from "./UserAdd";
import EditDialog from "./UserEdit";

export default {
  name: "view-admin-user-managment",
  components: {
    AddDialog,
    EditDialog
  },
  data: function() {
    return {
      session: {},
      list: [],
      isLoading: false,
      isPaginated: true,
      isPaginationSimple: true,
      paginationPosition: "bottom",
      tdefaultSortDirection: "asc",
      tsortIcon: "arrow-up",
      tsortIconSize: "is-small",
      currentPage: 1,
      perPage: 5,
      isAddDialogActive: false,
      isEditDialogActive: false,
      editItem: null
    };
  },
  methods: {
    onAdd() {
      this.isAddDialogActive = true;
    },
    onAddComplete() {
      this.getList();
      this.isAddDialogActive = false;
    },
    onAddCancel() {
      this.isAddDialogActive = false;
    },

    onEdit(obj) {
      this.editItem = obj;
      this.isEditDialogActive = true;
    },
    onEditComplete() {
      this.getList();
      this.isEditDialogActive = false;
    },
    onEditCancel() {
      this.isEditDialogActive = false;
    },

    nameForRow(row) {
      return row.lastName + ", " + row.firstName;
    },
    onDelete(obj) {
      // use the id to find the article and then delete it
      var msg = 'Are you sure you want to <b>delete</b> "';
      msg += obj.userName + '"? This action cannot be undone.';

      this.$buefy.dialog.confirm({
        title: "Deleting User",
        message: msg,
        confirmText: "Delete User",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          API.delete(
            API.url.authentication.user,
            this,
            obj.loginId,
            function(obj) {
              obj.getList();
              obj.$buefy.toast.open("User deleted!");
            },
            function(obj, err) {
              obj.showError(err);
            }
          );
        }
      });
    },
    showError(err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: err,
        position: "is-bottom",
        type: "is-danger"
      });
    },
    getList() {
      this.isLoading = true;
      API.list(
        API.url.authentication.user,
        this,
        function(obj, data) {
          obj.list = data.list;
          obj.isLoading = false;
        },
        function(obj, err) {
          obj.showError(err);
          obj.isLoading = false;
        }
      );
    }
  },
  mounted() {
    this.session = this.$attrs.session;
    this.getList();
  }
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1px) {
  #view-admin-user-managment {
    article {
      margin-bottom: 8px;
    }
    .media-content {
      font-size: 0.9em;
      .footnote {
        font-size: 8pt;
        vertical-align: top;
      }

      .footnotes {
        font-size: 8pt;
      }
    }
  }
}
@media screen and (min-width: 767px) {
  #view-admin-user-managment {
    article {
      margin-bottom: 10px;
    }
    .media-content {
      font-size: 1em;
    }
  }
}
</style>
