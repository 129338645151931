<template>
  <div id="view-admin-login-edit">
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>

    <b-card title="Edit User" :centered="true" class="dlg">
      <div class="media left">
        <div class="media-left is-hidden-touch">
          <figure class="image is-48x48">
            <img src="/icon/user_48x48.png" alt="Login Icon" />
          </figure>
        </div>
        <div class="media-content">
          <b-tabs
            v-model="activeTab"
            size="is-small"
            type="is-boxed"
            position="is-centered"
          >
            <b-tab-item label="User">
              <form action="" @submit="onUpdateUser">
                <div class="content">
                  <p>
                    The data entered here is what the system will use to
                    identify a user. however, the only publicly displayed
                    information is just first and last name.
                  </p>

                  <b-field label="Last Name" label-position="on-border">
                    <b-input
                      ref="login-lastName"
                      v-model="login.lastName"
                      placeholder="Enter last name"
                      required
                      autofocus
                      :disabled="disabled"
                    ></b-input>
                  </b-field>
                  <b-field label="First Name" label-position="on-border">
                    <b-input
                      v-model="login.firstName"
                      placeholder="Enter first name"
                      :disabled="disabled"
                    ></b-input>
                  </b-field>
                  <b-field label="Middle Name" label-position="on-border">
                    <b-input
                      v-model="login.middleName"
                      placeholder="Enter middle name"
                      :disabled="disabled"
                    ></b-input>
                  </b-field>
                  <hr />
                  <b-field label="e-mail" label-position="on-border">
                    <b-input
                      v-model="login.email"
                      type="email"
                      placeholder="Enter email"
                      :disabled="disabled"
                    ></b-input>
                  </b-field>
                  <b-field label="Phone" label-position="on-border">
                    <b-input
                      v-model="login.phone"
                      type="tel"
                      placeholder="Enter phone"
                      :disabled="disabled"
                    ></b-input>
                  </b-field>
                  <hr />
                  <b-field label="Status" label-position="on-border">
                    <b-select
                      required
                      v-model="login.statusId"
                      :disabled="disabled"
                    >
                      <option
                        v-for="t in statusList"
                        :value="t.statusId"
                        :key="t.statusId"
                      >
                        {{ t.description }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <div class="level is-mobile">
                  <div class="level-left">
                    <small v-if="error != ''" class="level-item is-danger">
                      {{ error }}
                    </small>
                  </div>
                  <div class="level-right">
                    <div class="field is-grouped">
                      <button
                        type="sumbit"
                        class="button is-link mr-3"
                        :disabled="disabled"
                      >
                        Save Changes
                      </button>
                      <button
                        type="cancel"
                        class="button is-link"
                        :disabled="disabled"
                        @click="onCancel"
                      >
                        Done
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </b-tab-item>

            <b-tab-item label="Password">
              <div v-if="!forcePassword">
                <form action="" @submit="onTriggerPasswordChange">
                  <div class="content">
                    <p>
                      A users password can be changed here, although it is
                      discouraged. A facility to trigger an email to the user
                      for them to reset their own password is the preferred
                      method for managing a users password.
                    </p>
                  </div>

                  <div class="level is-mobile">
                    <div class="level-left">
                      <a
                        href="#"
                        @click.prevent="forcePassword = !forcePassword"
                      >
                        {{
                          !forcePassword
                            ? "[ Show Manual Change ]"
                            : "[ Hide Manual Change ]"
                        }}
                      </a>
                    </div>
                    <div class="level-right">
                      <div class="field is-grouped">
                        <button
                          type="sumbit"
                          class="button is-link mr-3"
                          :disabled="disabled"
                        >
                          Trigger Password Change
                        </button>
                        <button
                          type="cancel"
                          class="button is-link"
                          :disabled="disabled"
                          @click="onCancel"
                        >
                          Done
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div v-if="forcePassword">
                <form action="" @submit="onForcePasswordChange">
                  <div class="content">
                    <p>
                      A users password can be changed here, although it is
                      discouraged. A facility to trigger an email to the user
                      for them to reset their own password is the preferred
                      method for managing a users password.
                    </p>
                  </div>
                  <b-field label="New Password" label-position="on-border">
                    <b-input
                      v-model="password.new"
                      placeholder="Enter your new password"
                      required
                      type="password"
                      autofocus
                      :disabled="disabled"
                    ></b-input>
                  </b-field>
                  <b-field
                    label="Confirm New Password"
                    label-position="on-border"
                  >
                    <b-input
                      v-model="password.confirm"
                      placeholder="Confirm your current password"
                      required
                      type="password"
                      autofocus
                      :disabled="disabled"
                    ></b-input>
                  </b-field>

                  <div class="level is-mobile">
                    <div class="level-left">
                      <a
                        href="#"
                        @click.prevent="forcePassword = !forcePassword"
                      >
                        {{
                          !forcePassword
                            ? "[ Show Manual Change ]"
                            : "[ Hide Manual Change ]"
                        }}
                      </a>
                    </div>
                    <div class="level-right">
                      <div class="field is-grouped">
                        <button
                          type="sumbit"
                          class="button is-link mr-3"
                          :disabled="disabled"
                        >
                          Force Password Change
                        </button>
                        <button
                          type="cancel"
                          class="button is-link"
                          :disabled="disabled"
                          @click="onCancel"
                        >
                          Done
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </b-tab-item>

            <b-tab-item label="Groups">
              <button
                type="cancel"
                class="button is-link"
                :disabled="disabled"
                @click="onCancel"
              >
                Done
              </button>
            </b-tab-item>

            <b-tab-item label="Permissions">
              <user-permissions :session="session" :user="login" />
              <div class="level is-mobile mt-6">
                <div class="level-left"></div>
                <div class="level-right">
                  <div class="field is-grouped">
                    <button
                      type="cancel"
                      class="button is-link"
                      :disabled="disabled"
                      @click="onCancel"
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import API from "@/Druware.Web.Shared/api/api";
import BCard from "@/Druware.Web.Shared/controls/Card";
import UserPermissions from "./UserPermissions";

export default {
  name: "view-admin-login-edit",
  components: {
    BCard,
    UserPermissions
  },
  props: {
    edit: Object,
    session: Object
  },
  data() {
    return {
      login: {},
      statusList: [],
      password: {
        current: "",
        new: "",
        confirm: ""
      },
      isLoading: false,
      isPaginated: true,
      disabled: false,
      activeTab: undefined,
      error: null,
      forcePassword: false
    };
  },
  watch: {
    edit(to, from) {
      if (to != from) {
        this.login = to;
        this.isLoading = false;
        return;
      }
      if (this.login == null) {
        this.getModel();
      }
    }
  },
  methods: {
    getStatusList() {
      API.list(
        API.url.authentication.picklist + "loginstatus/",
        this,
        function(obj, data) {
          obj.statusList = data;
          obj.isLoading = false;
        },
        function(obj, err) {
          obj.showMessage(
            "Unable to get login status picklist: " + err,
            "is-danger"
          );
        }
      );
    },
    getModel() {
      API.model(
        API.url.authentication.user,
        this,
        function(obj, data) {
          obj.login = data;
          obj.isLoading = false;
        },
        function(obj, err) {
          obj.showMessage("Unable to get model: " + err, "is-danger");
        }
      );
    },
    updateMemberships() {},
    onUpdateUser(e) {
      e.preventDefault();
      // sync up the login/contact overlap and post both
      this.disabled = true;
      API.put(
        API.url.authentication.user,
        this,
        this.login.loginId,
        this.login,
        function(obj, data) {
          obj.login = data;
          obj.disabled = false;
          obj.isLoading = false;
          obj.showMessage("Changes Saved!.", "is-success");
          obj.$emit("onComplete");
        },
        function(obj, err) {
          obj.disabled = false;
          obj.showMessage("Server returned an error: " + err);
        }
      );
    },
    onTriggerPasswordChange(e) {
      e.preventDefault();
      this.disabled = true;
      API.post(
        API.url.authentication.registration + this.login.loginId,
        this,
        this.login.loginId,
        function(obj, data, session) {
          obj.session = session;
          obj.$emit("onComplete");
          obj.showMessage(data, "is-success");
          obj.disabled = false;
          obj.$router.back();
        },
        function(obj, err) {
          obj.disabled = false;
          obj.showErrorMessage("Server returned an error: " + err);
        }
      );
    },
    onForcePasswordChange(e) {
      e.preventDefault();
      this.disabled = true;
      API.post(
        API.url.authentication.registration + this.login.loginId,
        this,
        this.login.loginId,
        this.password,
        function(obj, data, session) {
          obj.session = session;
          obj.showMessage(data, "is-success");
          obj.disabled = false;
          obj.$router.back();
        },
        function(obj, err) {
          obj.disabled = false;
          obj.showMessage("Server returned an error: " + err);
        }
      );
    },
    onCancel(e) {
      e.preventDefault();
      this.$emit("onCancel");
    },

    showMessage(msg, type = "is-danger") {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: "is-bottom",
        type: type
      });
    },
    hasTasks(obj) {
      return obj.hasTasks;
    },
    onDeletePermission(obj) {
      this.showMessage(obj);
    },
    onAddPermission() {}
  },
  mounted() {
    this.isLoading = true;
    this.getStatusList();
    if (this.edit == null) {
      this.getModel();
    }

    this.login = this.edit; // copy the prop to the internal value

    // this.getContact(this.login.loginId);
    // get the user

    /*Session.init(
      this,
      function (obj, data) {
        obj.session = data;
      },
      function (obj, err) {
        obj.showMessage("Unable to get session state: " + err, "is-danger");
      }
    );
    var name = this.$route.params.userName;
    if (name != null && name != "new") {
      User.get(
        this,
        this.$route.params.userName,
        function (obj, data) {
          obj.login = data;
          obj.getContact();
          obj.isLoading = false;
        },
        function (obj, err) {
          obj.showMessage("Unable to get login: " + err, "is-danger");
        }
      );
      return;
    }*/
  }
};
</script>

<style lang="scss" scoped>
#view-admin-login-edit {
  min-width: 80%;
  max-height: 90%;

  .dlg {
    min-width: 80%;
    max-height: 90%;
    overflow: hidden;
    /* Set our transitions up. */
    -webkit-transition: height 0.8s;
    -moz-transition: height 0.8s;
    transition: height 0.8s;
  }
}
</style>
